.Buy {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0, 0, 0, 0.6);
}

.Buy__error {
  color: red;
  font-size: 12px;
  font-weight: bold;
  margin-top: 0.5rem;
}

.Buy__section {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 12px;
  padding: 1.5rem;
  font-size: 14px;
  width: 360px;
  height: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 1rem 0;
  position: relative;
}

.Buy__section > hr {
  border: 0.5px solid #eeeeee;
  margin: 0.75rem 0;
}

.Buy__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Buy__header > h2 {
  font-size: 20px;
}

.Buy__section-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  border-radius: 6px;
}

.Buy__section-close > img {
  width: 28px;
  height: 28px;
}

.Buy__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Buy__content > .Input {
  width: 100%;
}

.Buy__simplex {
  margin-top: 1rem;
  width: 100%;
}

@media (max-width: 425px) {
  .Buy__section {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    border-radius: 0px;
  }
}
