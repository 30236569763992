@import url(reset.css);

body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ol-zoom {
  position: fixed !important;
  bottom: 1rem !important;
  right: 1em !important;
  top: auto !important;
  left: auto !important;
}

.ol-control {
  background-color: transparent !important;
}

.f4map-3D-2D-to2D,
.f4map-3D-2D-to3D,
.f4map-geoloc,
.f4map-zoomout,
.f4map-zoomin,
.ol-zoom-out,
.ol-zoom-in {
  font-size: 0 !important;
  z-index: 30;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: normal !important;
  border: none !important;
  border-radius: 3px !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  height: 1.75rem !important;
  width: 1.75rem !important;
  margin: 0 0 0.25rem 0 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.f4map-zoomin,
.ol-zoom-in {
  background-image: url('./zoom-in.svg') !important;
}

.f4map-zoomout,
.ol-zoom-out {
  background-image: url('./zoom-out.svg') !important;
}

.f4map-geoloc {
  background-image: url('./locate.svg') !important;
}
.f4map-3D-2D-to2D {
  background-image: url('./caret-up.svg') !important;
}
.f4map-3D-2D-to3D {
  background-image: url('./caret-down.svg') !important;
}

.f4map-nav {
  top: auto !important;
  bottom: 1.25rem;
}

.f4map-mode {
  top: 186px !important;
  right: 72px !important;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: normal !important;
  border: none !important;
  border-radius: 3px !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  display: none !important;
}

.f4map-compass {
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.f4map-compass-right,
.f4map-compass-left {
  filter: invert(100%);
}

.f4map-footer {
  display: none;
}

.TileLayer {
  opacity: 0.6;
}

@media (max-width: 425px) {
  .ol-zoom {
    bottom: 1rem !important;
  }
}
