.Mailbox__container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 70;
  width: 100%;
  height: 100%;
  top: 0;
}

.Mailbox {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: stretch;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: inherit;
  border-radius: 12px;
  border: none;
  font: inherit;
  position: relative;
}

.Mailbox__header {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  position: relative;
  border-right: 1px solid #e0e0e0;
}

.Mailbox__header > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0.25rem;
}

.Mailbox__header-filter {
  display: flex;
}

.Mailbox__header-filter > li > button {
  border: 1px solid #7c5050;
  color: #7c5050;
  border-radius: 12px;
  background-color: transparent;
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 80px;
}

.Mailbox__header-filter > li > button.selected {
  background-color: #7c5050;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.Mailbox__header-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 3px;
  width: 30px;
  height: 30px;
}

.Mailbox__header-properties {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.Mailbox__header-properties-text {
  font-size: 10px;
  color: grey;
}

.Mailbox__header-properties-digit {
  font-size: 36px;
}

.Mailbox__header-new {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.Mailbox__header-new > ion-icon {
  width: 24px;
  height: 24px;
}

.Mailbox__header-messages {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  overflow: auto;
}

.Mailbox__header-messages li:first-child {
  border-top: 1px solid #e0e0e0;
}

.Mailbox__header-messages-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.Mailbox__header-messages-item.selected {
  background-color: #e0e0e0;
}

.Mailbox__header-messages-item > * {
  cursor: pointer;
}

.Mailbox__header-messages-item-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.Mailbox__header-messages-item-name {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Mailbox__header-messages-item-date {
  font-size: 8px;
  margin-top: 0.5rem;
  color: #9e9e9e;
  display: flex;
}

.Mailbox__header-messages-item-nb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #7c5050;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}

.Mailbox__items {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Mailbox__items > ul {
  flex: 1;
  overflow: auto;
}

.Mailbox__items-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.Mailbox__items-header-name {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
}

.Mailbox__items-header-delete {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 3rem;
}

.Mailbox__item {
  margin: 0.5rem 0;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  cursor: pointer;
  padding: 0.75rem;
}

.Mailbox__item--unread {
  background-color: #fafafa;
  border: 1px solid #9e9e9e;
}

.Mailbox__item-message {
  flex: 1;
  display: flex;
}

.Mailbox__item-message-sender {
  justify-content: flex-start;
}

.Mailbox__item-message-receiver {
  justify-content: flex-end;
}

.Mailbox__item-message p {
  word-break: break-word;
  font: inherit;
  font-size: 14px;
  background-color: #e0e0e0;
  margin: 1rem;
  padding: 1rem;
  border-radius: 8px;
  max-width: 70%;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.Mailbox__item-message-receiver p {
  background-color: #e8f5e9;
}

.Mailbox__item-message span {
  font-size: 10px;
  margin-top: 0.5rem;
  color: #424242;
  align-self: flex-end;
}

.Mailbox__item-message-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 0.5rem;
}

.Mailbox__item-message-info > li > span {
  color: #9e9e9e;
  font-size: 9px;
}

.Mailbox__item-message-info > li > span.username {
  color: #424242;
  font-size: 15px;
  font-weight: bold;
}

.Mailbox__item-image {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 1rem;
}

.Mailbox_no-messages {
  color: #9e9e9e;
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

@media (max-width: 425px) {
  .Mailbox {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    flex-direction: column;
  }

  .Mailbox__header-messages {
    max-height: 200px;
    overflow: scroll;
  }

  .Mailbox__header {
    flex: 0 1 auto;
    max-height: 300px;
  }

  .Mailbox__items {
    flex: 1;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  .Mailbox__header-messages-item {
    padding: 0.5rem 0.75rem;
  }

  .Mailbox__header > div {
    align-items: center;
    padding-right: 60%;
  }
}
