.Profile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.6);
}

.Profile__signing {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 2rem;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.Profile__section {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 12px;
  padding: 2rem;
  font-size: 14px;
  width: 500px;
  height: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0;
  position: relative;
}

.Profile__section > p {
  line-height: 1.5;
}

.Profile__section > hr {
  border: 0.5px solid #eeeeee;
  margin: 1rem 0 0.5rem 0;
}

.Profile__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile__header-username > .Input__content {
  padding: 0;
  width: 120px;
  text-align: center;
  background-color: transparent;
}

.Profile__header-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin-bottom: 0.5rem;
}

.Profile__header-image-edit {
  position: absolute;
  top: 3.5rem;
  cursor: pointer;
}

.Profile__header-image--editable {
  cursor: pointer;
}

.Profile__header-username {
  line-height: 2;
  font-size: 20px;
}

.Profile__section-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  border-radius: 6px;
}

.Profile__section-form {
  margin-top: 1rem;
}

.Profile__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Profile__content > .Input {
  width: 48%;
}

.Profile__edit {
  margin-top: 2rem;
}

.Profile__section-error {
  font-size: 12px;
  color: red;
  margin-top: 0.5rem;
}

@media (max-width: 425px) {
  .Profile__section {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    border-radius: 0px;
  }
}
