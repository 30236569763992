.About {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.2);
}

.About__section {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 0.5rem;
  padding: 4rem 6rem 8rem 4rem;
  font-size: 14px;
  width: 75%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 1rem 0;
  position: relative;
}

.About__section > p {
  line-height: 1.5;
}

.About__header-title {
  font-size: 28px;
  line-height: 2;
}

.About__header-title-description {
  font-size: 16px;
}

.About__subtitle {
  font-size: 18px;
  line-height: 2;
  margin-top: 1rem;
}

.About__section-master {
  font: inherit;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background-color: #7c5050;
  padding: 0.5em 2rem;
  cursor: pointer;
  text-align: center;
  position: fixed;
  bottom: 9%;
  right: calc(50% - 210px);
  border-radius: 16px;
  border: none;
}

.About__section-close {
  position: fixed;
  top: 9%;
  right: 15%;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  border-radius: 6px;
}

@media (max-width: 425px) {
  .About__section {
    width: 100%;
    height: 100%;
    padding: 2rem 2rem 7rem 2rem;
    border-radius: 0px;
  }

  .About__section-close {
    top: 0.5rem;
    right: 0.5rem;
  }

  .About__section-close > img {
    width: 24px;
    height: 24px;
  }

  .About__section-master {
    bottom: 2rem;
    right: 2rem;
    left: 2rem;
    font-size: 20px;
  }
}
