.Menu {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 70;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
}

.Menu__badge {
  position: absolute;
  top: 0rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu__badge--item {
  right: 1rem;
  top: auto;
}

.Menu__profile {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 40;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  padding: 0;
}

.Menu__profile-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
  background-color: #fff;
}

.Menu__profile-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 40;
  width: auto !important;
}

.Menu__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.Menu__items {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  background-color: #fff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 6px;
}

.Menu__item {
  display: flex;
  flex: 1;
}

.Menu__item--highlight {
  font-weight: bold;
}

.Menu__item > button,
.Menu__item > a {
  padding: 0.75rem 2rem 0.75rem 1rem;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  flex: 1;
  background-color: inherit;
  border: none;
  font: inherit;
  text-align: start;
  display: flex;
  align-items: center;
}

.Menu__item > a:hover,
.Menu__item > button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Menu__item--divider {
  padding: 0 2rem;
  border: 0.5px solid #e5e5e5;
}

.Menu__item ion-icon,
.Menu__item img {
  margin-right: 0.75rem;
}

.Menu__item img {
  width: 18px;
}

@media (max-width: 425px) {
  .Menu {
    top: 0.5rem;
    right: 0.5rem;
  }

  .Menu__profile {
    left: auto;
    top: auto;
  }

  .App__profile-button {
    display: none;
  }
}
