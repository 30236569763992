.App {
  justify-content: center;
  display: flex;
}

.App__nav {
  display: flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: auto;
  width: 300px;
  background-color: transparent;
  z-index: 40;
}

.App__nav-search {
  width: 300px;
}

.App__nav--relative {
  position: relative;
  flex-direction: column;
  top: auto !important;
  left: auto !important;
  padding: 1rem;
}

.App__map {
  width: 100%;
}

.App__map--small {
  width: calc(100% - 300px);
}

.App__logo {
  position: fixed;
  bottom: 1rem;
  z-index: 60;
}

.App__logo-image {
  width: 5rem;
}

.App__expand {
  position: fixed;
  bottom: 6rem;
  right: 1.1rem;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.App__expand--3d {
  bottom: 1.5rem;
  right: 4.5rem;
}

.App__tooltip {
  position: absolute;
  width: 160px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 6px;
  padding: 1rem;
  font-size: 12px;
  z-index: 20;
}

@media (max-width: 768px) {
  .App__logo-image {
    width: 3rem;
  }
}

@media (max-width: 425px) {
  .App__nav-search {
    width: 260px;
  }
}
