.ThreeDMap__map {
  width: 100%;
  height: 100%;
}

.ThreeDMap {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
}

.ThreeDMap__close {
  position: fixed;
  top: 0.75rem;
  left: 0.75rem;
  border: none;
  background-color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  z-index: 30;
}
