.Reply {
  padding: 0 1rem 1rem 1rem;
}

.Reply__actions {
  display: flex;
  margin-top: 0.25rem;
  justify-content: flex-end;
}

.Reply__actions > button {
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
