.NewMessage {
  padding: 0 1rem 1rem 1rem;
}

.NewMessage__header {
  margin-top: 1.5rem;
}

.NewMessage__header > h1 {
  font-size: 20px;
  margin-bottom: 1rem;
}

.NewMessage__header > .Search__result-users {
  margin-top: 0.5rem;
}

.NewMessage__send {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.NewMessage__send button {
  border: none;
  background-color: #fff;
  cursor: pointer;
}
