.Location {
  position: absolute;
  width: 300px;
  display: flex;
  flex-direction: column;

  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: inherit;
  border-radius: 12px;
  border: none;
  font: inherit;
  z-index: 70;
}

.Location > hr {
  border: 0.5px solid #eeeeee;
  padding: 0 1rem;
  margin: 0;
}

.Location__header-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 3px;
  width: 32px;
  height: 32px;
}

.Location__header-image-button {
  position: absolute;
  top: -2.5rem;
  left: 7rem;
  cursor: pointer;
  border: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  padding: 0;
}

.Location__header-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;
}

.Location__location-user {
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Location__location-user-pseudo {
  color: inherit;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
}

.Location__location-user-link {
  font-size: 14px;
  margin-top: 0.25rem;
  color: inherit;
}

.Location__location-user-link > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.Location__location-image {
  height: 190px;
  width: auto;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}

.Location__location-address {
  padding: 0.75rem 1rem 0.25rem 1rem;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.Location__location-address-content {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.Location__location-address > span {
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 13px;
}

.Location__location-social,
.Location__location-actions {
  display: flex;
  padding: 0.2rem 1rem;
  justify-content: flex-end;
  align-items: center;
}

.Location__location-social {
  justify-content: space-between;
  border-top: 1px solid #7c5050;
}

.Location__location-social > li > button {
  display: flex;
}

.Location__location-actions-item-button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  margin-left: 0.5rem;
}

.Location__location-actions-item-button-flat {
  width: auto;
  height: auto;
  font: inherit;
  font-size: 9px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  padding: 0.35rem 0.5rem;
  border: 1px solid #000;
  margin-right: 0.5rem;
}

.Location__message {
  padding: 0.5rem;
}

.Location__message-message {
  margin-top: 0;
}

.Location__message-actions {
  display: flex;
  margin-top: 0.25rem;
  justify-content: space-between;
}

.Location__message-actions > li > button {
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.Location__footer {
  padding: 0.5rem 1rem 1rem 1rem;
}

.Location__footer > button {
  width: 100%;
}

.greyed {
  color: grey;
}

@media (max-width: 768px) {
  .Location {
    top: calc(50% - 160px) !important;
    right: 0 !important;
    left: 0 !important;
    margin-left: auto;
    margin-right: auto;
  }
}
