.Info {
  position: fixed;
  bottom: 1rem;
  left: 3.5rem;
  z-index: 40;
}

.Info__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Info__items {
  position: absolute;
  bottom: 2.5rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Info__items > li {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

.Info__items > li > a {
  flex: 1;
  cursor: pointer;
  padding: 0.75rem 1rem;
  text-decoration: none;
  color: inherit;
}

.Info__item--divider {
  padding: 0 2rem;
  border: 0.5px solid #e5e5e5;
}
