.Input {
  display: flex;
  color: inherit;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.Input__icon {
  position: absolute;
  margin-left: 16px;
}

.Input__label {
  color: #616161;
  font-size: 12px;
}

.Input__content {
  background-color: #ffffff;
  margin: 0;
  color: inherit;
  border-radius: 6px;
  border: none;
  padding: 12px 16px 12px 16px;
  border: 1px solid #e0e0e0;
  font: inherit;
  flex: 1;
}

input.Input__content:read-only {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.Input__content--error {
  background-color: #ffebee !important;
}
