.UpdateLocationDetails {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.65);
}

.UpdateLocationDetails__section {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 12px;
  padding: 2rem;
  font-size: 14px;
  max-width: 700px;
  width: 320px;
  height: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.UpdateLocationDetails__section > p {
  line-height: 1.5;
}

.UpdateLocationDetails__header-title {
  font-size: 20px;
  margin-bottom: 1rem;
}

.UpdateLocationDetails__section-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 6px;
}

.UpdateLocationDetails__section-close > img {
  width: 24px;
  height: 24px;
}

.UpdateLocationDetails__update {
  margin-top: 2rem;
  width: 100%;
}

.UpdateLocationDetails__section-form-error {
  font-size: 12px;
  color: red;
  margin-top: 0.5rem;
}

@media (max-width: 425px) {
  .UpdateLocationDetails__section {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    border-radius: 0px;
  }
}
