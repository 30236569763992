.Owner__container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 70;
  width: 100%;
  height: 100%;
  top: 0;
}

.Owner {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: inherit;
  border-radius: 12px;
  border: none;
  font: inherit;
}

.Owner__header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.Owner__header-close {
  /* FOR NOW */
  display: none !important;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 3px;
  width: 32px;
  height: 32px;
}

.Owner__header-image {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.Owner__header-user {
  padding: 0.5rem 1rem;
  flex: 1;
}

.Owner__header-user-pseudo {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0.25rem;
}

.Owner__header-user-address {
  font-size: 12px;
  color: grey;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 32px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Owner__header-properties {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.Owner__header-properties-text {
  font-size: 10px;
  color: grey;
}

.Owner__header-properties-digit {
  font-size: 48px;
}

.Owner__properties {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-height: 360px;
  overflow: auto;
}

.Owner__properties-item {
  margin: 0.5rem 0;
  display: flex;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  cursor: pointer;
}

.Owner__properties-item-details {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  flex: 1;
}

.Owner__properties-item-title {
  color: inherit;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5rem;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Owner__properties-item-address {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.Owner__properties-item-address--long {
  -webkit-line-clamp: 5; /* number of lines to show */
}

.Owner__properties-item-map {
  width: 100px;
  height: 90px;
  object-fit: cover;
  border-radius: 0 8px 8px 0;
}

.Owner__header-user-social {
  display: flex;
  margin-top: 0.5rem;
}

.Owner__header-user-social > li > a {
  margin-right: 0.5rem;
  cursor: pointer;
}

.Owner__header-user-social > li > a > ion-icon {
  color: #7c5050 !important;
}

@media (max-width: 425px) {
  .Owner {
    width: 360px;
  }
}
