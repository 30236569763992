.Contact {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 70;
  background-color: rgba(0, 0, 0, 0.2);
}

.Contact__section {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 0.5rem;
  padding: 3rem 4rem;
  font-size: 14px;
  width: 600px;
  height: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 1rem 0;
  position: relative;
}

.Contact__section > p {
  line-height: 1.5;
}

.Contact__header-title {
  font-size: 28px;
  line-height: 2;
}

.Contact__header-title-description {
  font-size: 16px;
}

.Contact__section-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  border-radius: 6px;
}

.Contact__section-form {
  margin-top: 1rem;
}

.Contact__send {
  margin-top: 1rem;
}

.Contact_error {
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
}

@media (max-width: 425px) {
  .Contact__section {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    border-radius: 0px;
  }
}
