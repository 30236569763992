.Snackbar {
  position: absolute;
  width: 300px;
  bottom: 1rem;
  left: calc(50% - 150px);
  color: #fff;
  z-index: 70;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Snackbar--success {
  background-color: #388e3c;
}

.Snackbar--error {
  background-color: #d32f2f;
}
