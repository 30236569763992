.SearchInput {
  display: flex;
  align-items: center;
  color: inherit;
}

.SearchInput__icon {
  position: absolute;
  margin-left: 16px;
}

.SearchInput__content {
  background-color: #ffffff;
  margin: 0;
  color: inherit;
  border-radius: 6px;
  border: none;
  padding: 12px 16px 12px 48px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  font: inherit;
  flex: 1;
}
