.TextArea {
  display: flex;
  color: inherit;
  flex-direction: column;
  margin-top: 1rem;
}

.TextArea__icon {
  position: absolute;
  margin-left: 16px;
}

.TextArea__content {
  background-color: #ffffff;
  margin: 0;
  color: inherit;
  border-radius: 6px;
  border: none;
  padding: 12px 16px 12px 16px;
  border: 1px solid #e0e0e0;
  font: inherit;
  font-size: 14px;
  flex: 1;
}

.TextArea__label {
  font-size: 12px;
  color: #757575;
}
