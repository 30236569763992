.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  font: inherit;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  cursor: pointer;
  color: #7c5050;
  font-weight: bold;
  letter-spacing: 1.1px;
}

.Button:disabled {
  cursor: default;
  box-shadow: none;
  opacity: 0.6;
}

.Button--flat {
  box-shadow: none;
  padding: 8px;
}

.Button--mini {
  font-size: 12px;
  border-radius: 2px;
  padding: 8px 16px;
  width: 100%;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Button--primary {
  color: #ffffff;
  background-color: #7c5050;
}

.Button__icon {
  margin-right: 12px;
}
