.Receiver {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  background-color: #7c5050;
  color: #fff;
  font: inherit;
  font-size: 14px;
  font-weight: bold;
  padding: 0.25rem 0.25rem 0.25rem 1.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  width: fit-content;
}

.Receiver button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background-color: #fff;
  margin-left: 0.75rem;
  border-radius: 50%;
  width: 26px;
  height: 26px;
}

.Receiver button img {
  width: 18px;
  height: 18px;
}
