.Search {
  position: absolute;
  top: 1rem;
  z-index: 40;
}

.Search__result-text {
  font-size: 10px;
  color: #424242;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 0.25rem;
  border-radius: 4px;
}

.Search__result-text > li {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.Search__result-text-number {
  font-weight: bold;
  font-size: 12px;
}

.Search__result-text-sign {
  width: 12px;
  height: 12px;
  background-color: #f46c62;
  border-radius: 50%;
  display: flex;
  margin-left: 0.25rem;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.Search__result-text-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Search__result-text-search > p {
  display: flex;
  align-items: center;
}

.Search__result-text-search-contract {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.Search__result-users {
  display: flex;
}

.Search__result-users-item-button {
  border: none;
  border-radius: 8px;
  background-color: #7c5050;
  color: #fff;
  font: inherit;
  font-size: 10px;
  font-weight: bold;
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

@media (max-width: 425px) {
  .Search {
    top: 1rem;
    left: 1rem;
  }
}
