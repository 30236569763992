.Signing {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0, 0, 0, 0.6);
}

.Signing__signing {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: none;
  border-radius: 3px;
  padding: 0.5rem 2rem;
  font-size: 16px;
  display: flex;
  justify-content: center;
}
