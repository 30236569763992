.FilterMenu {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 40;
}

.FilterMenu__badge {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.FilterMenu__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.FilterMenu__items {
  position: absolute;
  bottom: 2.5rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 0.5rem 1rem;
}

.FilterMenu__items > li {
  width: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem 0.5rem;
  font-size: 14px;
}

.FilterMenu__items > li > span {
  flex: 1;
  cursor: pointer;
}

.FilterMenu__item--owned,
.FilterMenu__item {
  background-color: #728378 !important;
  border: 1px solid #fff !important;
  width: 24px !important;
  height: 24px !important;
}

.FilterMenu__item--search {
  width: 20px !important;
  height: 20px !important;
}

.FilterMenu__item--owned {
  border: 4px solid #012f04 !important;
  background-color: #728378 !important;
}

.FilterMenu__item-icon {
  width: 28px !important;
  height: 28px !important;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
}

.FilterMenu__item-check {
  background-color: #fff;
  border: none;
  margin: 0;
  display: flex;
  cursor: pointer;
  padding: 0;
}

.FilterMenu__item-check > img {
  width: 1.5rem;
  height: 1.5rem;
}
