.Loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.Loading__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Loading__loader img {
  width: 6rem;
}

.LoadingIndicator {
  background-color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}

.LoadingIndicator svg path,
.LoadingIndicator svg rect {
  fill: #7c5050;
}
