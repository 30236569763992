.PageNotFound__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 6rem 0;
}

.PageNotFound {
  display: flex;
  flex-direction: column;
}

.PageNotFound__title {
  font-size: 32px;
  margin-bottom: 1rem;
}

.PageNotFound__description {
  line-height: 1.5;
}
